@import "variables";

a,
.btn-link {
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: $secondary;
    }
}

.btn-link {
    border-radius: 0;
    border-bottom: 1px solid $border-color;
    &:hover {
        border-bottom: 1px solid $primary;
    }
}
