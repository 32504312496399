@import "variables";

.btn.change-picture {
    position: absolute;
    background-color: #fff;
    color: #333;
    box-shadow: $box-shadow;
    font-size: 14px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: #f0f0f0;
    }
}
