// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

$primary: #383838;
$secondary: #00a7be;
$danger: #ce332a;
$success: #2f9428;
$font-family-base: "Montserrat", sans-serif;
$headings-font-family: "Quicksand", sans-serif;
$headings-font-weight: 400;
$container-padding-x: 60px;
$border-color: #ddd;
$light-grey: #f0f0f0;
$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

// Headers
$ion-header-background: #fff;
$ion-header-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

// Content
$ion-content-background: #fff;

// Font size
$font-size-base: 13px;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.15;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.7;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$nav-link-height: 40px;

// Cards
$card-border-width: 0;
$card-background: #fff;
$card-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
$card-border-radius: 10px;

// Modals
$modal-background: #fff;

// Forms
$input-btn-focus-box-shadow: none;
$input-border-radius: 10px;
$input-bg: #f0f0f0;
$input-border-width: 0;
$input-group-addon-bg: #f0f0f0;
$input-group-addon-color: #000;

// Spacers
$spacers: (
    0: 0,
    1: 0.25rem,
    2: 0.5rem,
    3: 1rem,
    4: 1.5rem,
    5: 3rem,
    6: 4rem,
    7: 5rem,
);

// Container
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1600px,
);
